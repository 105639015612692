<template>
  <el-container class="design-container">
    <el-main class="design-content">
      <!-- <div class="content--left"></div> -->
      <div id="workspace" class="content--center">
        <div class="editor-canvas">
          <div
            class="editor-canvas-bg"
            :style="{
              width: canvasW * zoomScale + 'px',
              height: canvasH * zoomScale + 'px',
            }"
          ></div>
          <fabric-editor
            v-for="(item, index) in designFaceData"
            v-show="index == faceIndex"
            :key="index"
            :index="index"
            :value="item"
            :width="canvasW"
            :height="canvasH"
            :left="designFaceShiftX"
            :top="designFaceShiftY"
            ref="editor"
            @zoomChange="handleZoomChange"
            @confirm="setCanvas(index)"
          ></fabric-editor>
        </div>
      </div>
      <div class="content--right" style="position: absolute; right: 0">
        <div class="design-effect">
          <div class="type-btn" v-if="is3d">
            <div
              class="type-btn-item btn-3d"
              :class="effectType == 3 ? 'on' : ''"
              @click="effectType = 3"
            >
              3D
            </div>
            <div
              class="type-btn-item btn-2d"
              :class="effectType == 2 ? 'on' : ''"
              @click="effectType = 2"
            >
              2D
            </div>
          </div>
          <div
            v-if="is3d"
            class="effect-preview"
            :style="{ 'z-index': effectType == 3 ? 99 : 98 }"
          >
            <effect
              ref="threeRef"
              :modelUrl="[JSON.parse(productInfo?.model.model_url)]"
              :type="3"
              :id="99"
              :modelParams="modelParams"
            ></effect>
          </div>
          <div
            class="effect-preview"
            :style="{ 'z-index': effectType == 2 ? 99 : 98 }"
          >
            <el-carousel
              :height="600 + 'px'"
              arrow="always"
              :autoplay="false"
              trigger="click"
            >
              <el-carousel-item
                v-for="(item, index) in effectData"
                :key="index"
                :name="item.design_name"
              >
                <effect
                  ref="effectRef"
                  :value="item"
                  :imgLayer="
                    currentSpecName ? item.imgLayer[currentSpecName] : 1
                  "
                  :modelUrl="
                    currentSpecName ? item.model_url[currentSpecName] : []
                  "
                  :id="index"
                  :type="2"
                  :images="currentSpecName ? item.images[currentSpecName] : []"
                  :modelParams="modelParams"
                ></effect>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <canvas
        ref="effectCanvas"
        id="effectCanvas"
        style="position: absolute; left: 100%; z-index: 1"
        width="1600"
        height="1600"
      ></canvas>
    </el-main>
  </el-container>
</template>
<script>
import FabricEditor from "./components/fabric-editor.vue";
import Effect from "./components/effect.vue";
import OSS from "@/utils/oss.js";
import md5 from "js-md5";
import { otherGetRequest, otherPostRequest, deepClone } from "@/utils/util.ts";
export default {
  components: {
    FabricEditor,
    Effect,
  },

  data() {
    return {
      id: 0,
      workspaceWidth: 0,
      workspaceHeight: 0,
      canvasL: 0,
      canvasT: 0,
      designFaceShiftX: 0,
      designFaceShiftY: 0,
      canvasW: 650,
      canvasH: 650,
      zoomScale: 1,
      canvasArr: [],
      fabricCanvas: null,
      editor: null,
      effectRef: null,
      currentImgW: "",
      currentImgH: "",
      productInfo: {},
      faceIndex: 0,
      faceName: "",
      currentFace: {},
      designFaceData: [],
      effectData: [{}],
      currentSpecName: "",
      modelParams: {},
      effectIndex: 0,
      effectType: 2,
      is3d: false,
      design_url: "",
      ossClass: null,
    };
  },
  watch: {
    faceIndex: {
      handler(val) {
        this.editor = this.$refs.editor[val];
        this.fabricCanvas = this.$refs.editor[val].canvas;
        this.currentFace = this.designFaceData[val];
        this.faceName = this.designFaceData[val].design_name;
        if (this.currentFace.model_view) {
          const rotation = this.currentFace.model_view
            .split(",")
            .map((item) => Number(item) * 10);
          this.$refs.threeRef.moveCamera({
            x: rotation[0],
            y: rotation[1],
            z: rotation[2],
          });
        }
      },
    },
    effectIndex: {},
  },
  computed: {},
  methods: {
    setCanvas(index) {
      if (index == 0) {
        this.faceIndex = index;
      }
    },
    handleZoomChange(value) {
      const workspaceElement = document.getElementById("workspace");
      this.zoomScale = value;
      if (workspaceElement) {
        this.designFaceShiftX =
          (workspaceElement.offsetWidth - this.canvasW * value) / 2;
        this.designFaceShiftY =
          (workspaceElement.offsetHeight - this.canvasH * value) / 2;
      }
    },

    createDesignFaces(designFaceList) {
      return new Promise(async (resolve, reject) => {
        const faceData = [];
        const modelParams = {};
        for (const index in designFaceList) {
          const face = designFaceList[index];
          let cloneData = deepClone(face);
          if ([1, 3].indexOf(face.is_diy) > -1) {
            if (cloneData.more_spec == 1) {
              let sku_diy_data = JSON.parse(deepClone(cloneData.sku_diy_data));
              let currentData = sku_diy_data.find(
                (item) => item.name == this.currentSpecName
              );
              cloneData = { ...cloneData, ...currentData };
            }

            faceData.push(cloneData);
            const modelData = deepClone(face);
            if (modelData.normalmap) {
              const normalmap = JSON.parse(modelData.normalmap);
              modelData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              modelData.normalMap = null;
            }
            modelData.default_texture = modelData.texture;
            modelParams[face.design_name] = modelData;
          } else {
            if (cloneData.normalmap) {
              const normalmap = JSON.parse(cloneData.normalmap);
              cloneData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              cloneData.normalMap = null;
            }
            modelParams[face.design_name] = cloneData;
          }
        }
        this.designFaceData = faceData;
        this.modelParams = modelParams;
        resolve(true);
      });
    },

    createEffectDatas(effectRes) {
      return new Promise(async (resolve, reject) => {
        let effectArr = [];
        if (effectRes.length > 0) {
          for (const i in effectRes) {
            const item = deepClone(effectRes[i]);
            let images = {};
            let model_url = {};
            let imgLayer = {};
            if (item.more_spec == 1) {
              for (let skuData of JSON.parse(deepClone(item.sku_diy_data))) {
                images[skuData.name] = skuData.images;
                model_url[skuData.name] = skuData.model;
                imgLayer[skuData.name] = Number(skuData.img_layer);
              }
            } else {
              let imageTemp = deepClone(item.images)
                ? deepClone(item.images).split(",")
                : [];
              for (let spec of this.productInfo.spec_value_list) {
                images[spec.spec_value_str] = imageTemp;
                model_url[spec.spec_value_str] = JSON.parse(item.model_url);
                imgLayer[spec.spec_value_str] = Number(item.img_layer);
              }
            }
            item.images = images;
            item.model_url = model_url;
            item.imgLayer = imgLayer;
            item.currentImgs = images[this.currentSpecName];
            item.currentImgIndex = imgLayer[this.currentSpecName];
            item.preview_img = "";
            item.preview_loaded = false;
            effectArr.push(item);
          }
          this.effectData = effectArr;
        }
        resolve(true);
      });
    },

    async refreshTexture(faceIndex = null, faceName = "") {
      const canvas = !faceIndex
        ? this.fabricCanvas
        : this.$refs.editor[faceIndex].canvas;
      const designName = faceName || this.faceName;
      let defaultBgColor = null;
      let defaultTexture = null;
      // 使用默认底色
      if (!canvas.backgroundColor && this.currentFace.bg_color) {
        defaultBgColor = this.currentFace.bg_color;
      }
      if (defaultBgColor) {
        canvas.setBackgroundColor(
          defaultBgColor,
          canvas.renderAll.bind(canvas)
        );
      }
      // 使用默认贴图
      if (this.currentFace.texture) {
        defaultTexture = this.currentFace.texture;
        await this.setDefaultTexture(defaultTexture);
      }
      const overlay = canvas.overlayImage;
      canvas.overlayImage = null;

      const scale = 1600;
      const texture = canvas.toDataURL({
        left: this.designFaceShiftX,
        top: this.designFaceShiftY,
        width: this.canvasW * this.zoomScale,
        height: this.canvasH * this.zoomScale,
        multiplier: scale / (this.canvasW * this.zoomScale),
        format: "png",
      });
      if (this.is3d) {
        this.$refs.threeRef.setMapTexture({
          name: designName,
          url: texture,
        });
      }
      for (let index in this.effectData) {
        let effect = this.effectData[index];
        if (effect.is_diy) {
          effect.bigimg = await this.$refs.effectRef[index].setMapTexture({
            name: designName,
            url: texture,
          });
        }
      }
      canvas.overlayImage = overlay;
      // 清除默认贴图
      if (defaultTexture) {
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
      }
      // 清除默认底色
      if (defaultBgColor) {
        canvas.setBackgroundColor("", canvas.renderAll.bind(canvas));
      }
    },

    async handleSave() {
      let images = [];
      for (let index in this.effectData) {
        let effect = this.effectData[index];
        let imgs = effect.images[this.currentSpecName];
        if (effect.is_diy) {
          if (effect.bigimg) {
            imgs.splice(effect.currentImgIndex, 0, effect.bigimg);
          }
        }
        let img = await this.createEffectCanvas(imgs, effect.img_suffix);
        img = await this.ossClass.putb64ToOss({ content: img, type: "effect" });
        images.push(img);
      }
      let params = {
        id: this.id,
        images: images,
      };
      otherPostRequest("/goods.goodsDesignTask/syncTaskResult", params, {
        sign: md5(
          JSON.stringify(params) + "332994CF-622B-44FB-82DD-C11B1FFD95B5"
        ),
      }).then((res) => {
        this.$message.success("保存成功");
      });
    },

    createEffectCanvas(images, suffix) {
      return new Promise((resolve, reject) => {
        const canvas = this.$refs.effectCanvas;
        canvas.height = 1600;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          const imglen = images.length;
          (function f(n) {
            if (n < imglen) {
              const image = new Image();
              if (images[n] == "") {
                return f(n + 1);
              }
              image.src = images[n];
              image.setAttribute("crossOrigin", "anonymous");
              image.onload = () => {
                ctx.drawImage(image, 0, 0, 1600, 1600);
                f(n + 1);
              };
            } else {
              resolve(canvas.toDataURL(`image/${suffix || "png"}`));
            }
          })(0);
        }
      });
    },

    loadOldDesignDatas() {
      return new Promise((resolve, reject) => {
        fetch(this.design_url)
          .then((response) => response.json())
          .then((res) => {
            let length = 0;
            for (let index in res) {
              let canvas = this.$refs.editor[index].canvas;
              canvas.loadFromJSON(res[index], function () {
                // 在这里可以执行特定操作，比如调整画布大小或添加事件监听器等
                canvas.renderAll();
                length += 1;
                if (length == res.length) {
                  resolve(true);
                }
              });
            }
          });
      });
    },

    async createDesignDatas() {
      if (
        this.productInfo.model.is_three &&
        this.productInfo.model.model_url != ""
      ) {
        this.is3d = true;
      }

      await Promise.all([
        this.createDesignFaces(this.productInfo.design_parts),
        this.createEffectDatas(this.productInfo.design_effects),
      ]);
      this.editor = this.$refs.editor[0];
      this.fabricCanvas = this.$refs.editor[0].canvas;
      this.currentFace = this.designFaceData[0];
      this.faceName = this.designFaceData[0].design_name;
      setTimeout(() => {
        if (this.is3d) {
          this.$refs.threeRef.init();
        }
        for (const i in this.effectData) {
          this.$refs.effectRef[i].init();
        }
        if (this.design_url) {
          const toast = this.$message({
            message: "正在还原设计内容...",
            center: true,
            type: "success",
          });
          setTimeout(async () => {
            await this.loadOldDesignDatas(this.design_url);
            for (let index in this.designFaceData) {
              this.faceIndex = index;
              const name = this.designFaceData[index].design_name;
              await this.refreshTexture(index, name);
            }

            this.handleSave();
            toast.close();
            window.close();
          }, 500);
        }
      }, 100);
    },
  },

  async mounted() {
    let theRequest = {};
    if (location.search.indexOf("?") > -1) {
      let strs = location.search.split("?")[1].split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    if (!theRequest["id"]) {
      this.$message.error("参数错误");
      return;
    }
    const workspaceElement = document.getElementById("workspace");
    if (workspaceElement) {
      this.workspaceWidth = workspaceElement.offsetWidth;
      this.workspaceHeight = workspaceElement.offsetHeight;
    }
    let params = { id: theRequest["id"] };
    await otherGetRequest(`/goods.goodsDesignTask/getTaskDetail`, params, {
      sign: md5(
        JSON.stringify(params) + "332994CF-622B-44FB-82DD-C11B1FFD95B5"
      ),
    }).then((res) => {
      this.id = params.id;
      this.design_url = res.design.design_url;
      this.currentSpecName = res.task.spec_value_str;
      this.productInfo = res.model;
      this.ossClass = new OSS(res.sts_token);
      this.createDesignDatas();
    });
  },
};
</script>
